<template>
  <div>
    <div>
      <el-row>
        <el-col :span="30" style="display:flex;">
          <el-input
            class="input"
            v-model="keywordsStr"
            placeholder="请输入设备编码或关键字"
          ></el-input>
          <el-select
            clearable
            class="selects"
            v-model="clientele"
            placeholder="请选择客户"
          >
            <el-option
              v-for="item in clienteleItem"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <select-tree
            style="width:168px"
            ref="selectTree"
            :props="props"
            placeholder="请选择组织"
            :options="deptItem"
            :clearable="true"
            :accordion="true"
            @getValue="getValue($event)"
          />
          <el-select
            clearable
            class="select_state"
            v-model="onlineState"
            placeholder="在线状态"
            style="margin-left:10px"
          >
            <el-option
              v-for="item in onlineStateItem"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            clearable
            class="select_state"
            v-model="faultState"
            placeholder="设备状态"
          >
            <el-option
              v-for="item in faultStateItem"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            clearable
            class="select_state"
            v-model="condition.deviceType"
            placeholder="设备类型"
          >
            <!-- <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option> -->
            <el-option
              v-for="(item, index) in storage.deviceType"
              :key="index"
              :label="item"
              :value="item"
              v-show="item"
            >
            </el-option>
          </el-select>
          <el-select
            clearable
            class="select_state"
            v-model="condition.communicationModel"
            placeholder="通信方式"
          >
          <el-option v-for="item in communicationModelList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
          </el-select>
          <el-select
            v-model="dynamicColumnsDataList"
            v-bind="$attrs"
            multiple
            collapse-tags
            @change="changeSelect"
          >
        <el-option v-for="item in dynamicColumnsList" :key="item.name" :label="item.prop" :value="item.name">
            <el-checkbox v-model="item.isCheck">{{ item.prop }}</el-checkbox>
       </el-option>
  </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-button class="buttons query" @click="getdeviceMonitorList"
            >查询</el-button
          >
          <el-button
            class="buttons import"
            @click="dialogVisible = !dialogVisible"
            >导入</el-button
          >
          <el-button class="buttons import" @click="exportPutInformations"
            >导出</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="contents">
      <div
        @click="particulars(item.deviceImei)"
        class="box_card"
        :key="index"
        v-for="(item, index) in deviceData"
      >
        <div class="box_card_header">
          <span>{{ item.deptName }}</span>
          <span class="onlineStatus">{{
            item.onlineStatus == 2 ? "离线" : "在线"
          }}</span>
        </div>
        <div class="box_card_contents">
          <p style="margin-bottom: 6px">
            设备温度：{{ item.deviceTemperature }}
          </p>
          <p style="margin-bottom: 6px">设备类型：{{ item.deviceType }}</p>
          <p style="margin-bottom: 6px">资产编码：{{ item.assetCode }}</p>
          <p style="margin-bottom: 6px">通信方式：{{ item.communicationModel }}</p>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="app-page-container page">
      <span @click="first">首页</span>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="pager"
        :hide-on-single-page="false"
        :page-size="15"
        :current-page="currentPage"
        :total="recordCount"
      >
      </el-pagination>
      <span @click="last">尾页</span>
    </div>
    <el-dialog
      title="请选择上传文件"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-upload
        :data="{
          token: token,
          userId: userId
        }"
        class="upload-demo"
        accept=".xlsx,.xlsx"
        :show-file-list="true"
        :on-success="uploads"
        :action="url"
        :headers="{ coldChainAMHead: coldChainAMHead }"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploading">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  deviceMonitorList,
  exportPutInformation,
  getDynamicColumns
} from "@/api/equipmentMonitoring.js";
import {
  clientName,
  getDeviceTypes,
  queryDeptAll
} from "@/api/AssetManagement.js";
export default {
  name: "index",
  data() {
    return {
      keyWord: "",
      keywordsStr: "",
      clientele: "",
      clienteleItem: [],
      onlineState: "",
      faultStateItem: [
        {
          value: "1",
          label: "正常"
        },
        {
          value: "2",
          label: "维修"
        },
        {
          value: "3",
          label: "报废"
        }
      ],
      faultState: "",
      onlineStateItem: [
        {
          name: "离线",
          value: "2"
        },
        {
          name: "在线",
          value: "1"
        }
      ],
      communicationModelList:[
      {
          value: "1",
          label: "2G"
        },
        {
          value: "2",
          label: "4G"
        },
      ],
      dept: "",
      deptItem: [],
      dialogVisible: false,
      token: localStorage.getItem("token"),
      userId: localStorage.getItem("userId"),
      coldChainAMHead: localStorage.getItem("coldChainAMHead"),
      url: window.baseUrl + "/clodchainAM/web/batch_import_put_report",
      value: "",
      options: [
        {
          label: "22",
          value: 1
        }
      ],
      deviceData: [],
      pages: 1,
      pageSize: 15, //每页数据条数
      recordCount: 0,
      currentPage: 1, //当前页数
      storage: {
        deviceType: []
      },
      condition: {
        deviceType: "",
        communicationModel:''
      },
      props: {
        value: "deptId",
        label: "deptName",
        children: "children"
      },
      dynamicColumnsList:[],
      dynamicColumnsDataList:{},
      optionsChanged:false,
      fieldList:[]
    };
  },
  created() {
    this.getdeviceMonitorList();
    this.getClientName();
    this.getOrganization();
    this.getDeviceType();
    this.DynamicColumns();
  },
  methods: {
    getValue(value) {
      this.dept = value;
    },
    //导出投放信息
    exportPutInformations() {
      this.$store.commit("SET_LOADING", true);
      this.fieldList = this.dynamicColumnsList.filter(item => item.isCheck)
      // console.log(this.fieldList)
      let fieldValue = []
      if(this.fieldList.length>0){
        this.fieldList.map(item=>{
          return fieldValue += item.name+','
        })
      }
      let ids = [];
      if (this.keywordsStr !== "") {
        ids = this.keywordsStr
          .replace(/\s*/g, "")
          .split(/[\uff0c|,]/)
          .filter(item => item !== "")
          .map(item => {
            return item.replace(/\s*/g, "");
          });
      }
      let datas = {
        userId: localStorage.getItem("userId"),
        keyWord: this.keyWord,
        keywordsStr: ids.join(","),
        customerId: this.clientele,
        deptId: this.dept,
        onLineStatus: this.onlineState,
        status: this.faultState,
        isInWareHouse: 1,
        deviceType: this.condition.deviceType,
        communicationModel:this.condition.communicationModel,
        fieldList:fieldValue,
      };
      // console.log(datas)
      exportPutInformation(datas)
        .then(res => {
          const dt = new Date();
          const y = dt.getFullYear();
          const m = (dt.getMonth() + 1 + "").padStart(2, "0");
          const d = (dt.getDate() + "").padStart(2, "0");
          const hh = (dt.getHours() + "").padStart(2, "0");
          const mm = (dt.getMinutes() + "").padStart(2, "0");
          const ss = (dt.getSeconds() + "").padStart(2, "0");
          let date = y + m + d + hh + mm + ss;
          const content = res.data; //返回的内容
          let _this = this;
          if (res.data.type !== "application/vnd.ms-excel") {
            //二进制转字符串
            let reader = new FileReader();
            reader.addEventListener("loadend", function(e) {
              // 输出字符串 转化为json对象
              let str = JSON.parse(e.target.result);
              console.log(str)
              _this.$message({
                showClose: true,
                message: str.errorDesc,
                type: "error"
              });
              if (str.resultCode == 2000) {
                _this.$router.push("/login");
              }
            });
            reader.readAsText(content);
            this.fieldList = []
          } else {
            const fileName = "设备监控信息列表" + date.toString() + ".xlsx"; //下载文件名
            //处理下载流
            const blob = new Blob([content]); //创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
            const url = window.URL.createObjectURL(blob); //URL.createObjectURL(object)表示生成一个File对象或Blob对象
            let dom = document.createElement("a"); //设置一个隐藏的a标签，href为输出流，设置download
            dom.style.display = "none";
            dom.href = url;
            dom.setAttribute("download", fileName); //指示浏览器下载url,而不是导航到它；因此将提示用户将其保存为本地文件
            document.body.appendChild(dom);
            dom.click();
            this.fieldList = []
          }
        })
        .finally(() => {
          this.$store.commit("SET_LOADING", false);
        });
    },
    //获取设备类型
    getDeviceType() {
      getDeviceTypes({}).then(res => {
        this.storage.deviceType = res.data.data || [];
      });
    },
    //获取组织
    getOrganization() {
      const config = {
        params: {
          userId: localStorage.getItem("userId")
        }
      };
      queryDeptAll(config).then(({ data }) => {
        const arr = data.data || [];
        arr.forEach(value => {
          value.children = [];
          arr.forEach(val => {
            if (value.deptId === val.parentId) {
              value.children.push(val);
            }
          });
          // if (value.parentId === 0) {
          //   this.deptItem.push(value);
          // }
          if (value.deptId === parseInt(localStorage.getItem("deptId"), 10)) {
            this.deptItem.push(value);
          }
        });
      });
    },
    //获取客户列表
    getClientName() {
      clientName({}).then(res => {
        this.clienteleItem = res.data.data;
      });
    },
    //导入回调
    uploads(response, file, fileList) {
      if (response.desc == "SUCCESS") {
        this.$message({
          showClose: true,
          message: `导入成功!${response.data.failMessage}`,
          type: "success"
        });
        this.getdeviceMonitorList();
      } else {
        this.$message({
          showClose: true,
          message: "导入失败!",
          type: "error"
        });
      }
    },
    //弹框
    handleClose(done) {
      done();
    },
    //上传
    uploading() {
      this.dialogVisible = false;
      this.getdeviceMonitorList();
    },
    //跳转详情页
    particulars(data) {
      this.$router.push({
        path: "/dashboard/equipmentMonitoring/particulars",
        query: {
          deviceRfId: data
        }
      });
    },
    //查询
    getdeviceMonitorList() {
      let data = {
        curPage: this.currentPage,
        limit: 15,
        isInWareHouse: 1,
        keyWord: this.keyWord,
        keywordsStr: this.keywordsStr
          .replace(/\s*/g, "")
          .split(/[\uff0c|,]/)
          .filter(item => item !== "")
          .map(item => {
            return item.replace(/\s*/g, "");
          })
          .join(","),
        customerId: this.clientele,
        deptId: this.dept,
        onLineStatus: this.onlineState,
        status: this.faultState,
        deviceType: this.condition.deviceType,
        communicationModel:this.condition.communicationModel
      };
      deviceMonitorList(data).then(res => {
        this.recordCount = res.data.data.total;
        this.pages = res.data.data.pages;
        this.deviceData = res.data.data.records;
      });
    },
    //当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getdeviceMonitorList();
    },
    //分页首页
    first() {
      // this.tableData = [];
      this.currentPage = 1;
      this.getdeviceMonitorList();
      // let data = {curPage: 1, limit: 12};
    },
    //分页最后一页
    last() {
      // this.tableData = [];
      this.currentPage = this.recordCount;
      let data = {
        curPage: this.pages,
        limit: 15,
        isInWareHouse: 1
      };
      deviceMonitorList(data).then(res => {
        this.recordCount = res.data.data.total;
        this.pages = res.data.data.pages;
        this.deviceData = res.data.data.records;
      });
      // let data = {curPage: this.pages, limit: 12};
    },
    //获取导出筛选项
    DynamicColumns(){
      this.dynamicColumnsDataList= []   
      getDynamicColumns({}).then(({data})=>{
        // console.log(data.data)
        data.data.forEach(item => {
          item.isCheck = true
        })
        this.dynamicColumnsList = data.data
        this.dynamicColumnsDataList = data.data
        // console.log(this.dynamicColumnsList)
      })
    },
    changeSelect(){
      this.dynamicColumnsDataList = this.dynamicColumnsList.filter(item => item.isCheck)  
    }
  }
};
</script>

<style scoped>
.onlineStatus {
  display: inline-block;
  float: right;
  margin-right: 14px;
  color: #00cccc !important;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  letter-spacing: 0;
  text-align: right;
  font-weight: bold;
  line-height: 20px;
}
.box_card_header span {
  font-weight: bold;
  line-height: 42px;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #3a3a3a;
  letter-spacing: -0.05px;
  margin-left: 14px;
}
/*解决高度塌陷*/
.clearfix:after {
  /*添加一个内容*/
  content: "";
  /*转换为一个块元素*/
  display: block;
  /*清除两侧的浮动*/
  clear: both;
}
.box_card_header {
  height: 42px;
  background: #e8eaef;
}
.box_card_contents {
  height: 88px;
  background: #ffffff;
  padding: 10px;
  border: 1px solid #ebecee;
  box-shadow: 0 0 15px 0 #eaebec;
  border-radius: 3px;
  border-radius: 3px;
}
.box_card_contents p {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #565d75 !important;
  letter-spacing: 0;
  line-height: 20px;
}
.box_card {
  cursor: pointer;
  width: 280px;
  border: 1px solid #ebecee;
  box-shadow: 0 0 15px 0 #eaebec;
  border-radius: 3px;
  border-radius: 3px;
  height: 140px;
  margin-right: 20px;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
.contents {
  flex-flow: row wrap;
}
.buttons {
  width: 96px;
  margin-top: 20px;
}
.input {
  width: 246px;
  margin-right: 14px;
}
.selects {
  width: 168px;
  margin-right: 14px;
}
.select_state {
  width: 120px;
  margin-right: 14px;
}
</style>
